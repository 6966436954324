import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledAdoptNoDIY = styled.div`
  ${(props) => props.isWhite && css`
    background-color: ${Colors.white};
    color: ${Colors.black};
  `}
`;

export const StyledItemsScroller = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  padding: ${Rem(16)} 0;
  justify-content: space-between;

  @media (${BP.twoColumns}){
    justify-content: flex-start;
    overflow: unset;
  }
`;

export const StyledItem = styled.div`
  min-width: ${Rem(210)};
  max-width: ${Rem(210)};
  text-align: center;


  @media (${BP.twoColumns}){
    &:first-child {
      margin-right: ${Rem(22)};
    }

    &:last-child {
      margin-left: ${Rem(22)};
    }
  }

  &:last-child {
    margin-top: -1px;

    &:after {
      position: relative;
      content: '';
      display: block;
      width: ${Rem(20)};
      height: 1px;
      left: 100%;

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }
`;

export const StyledItemImage = styled.div`
  width: ${Rem(124)};
  height: ${Rem(82)};
  margin: 0 auto ${Rem(12)};
  background-image: url('${(props) => props.bg}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const StyledItemDescription = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
`;
